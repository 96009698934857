import React, { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";
import { toast } from "react-hot-toast";
import SalesChart from '../charts/SalesChart';
import { useLazyGetDashboardSalesQuery, useGetAdminOrdersQuery } from "../../redux/api/orderApi";
import Loader from "../layout/Loader";
import MetaData from "../layout/MetaData";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const [getDashboardSales, { error, isLoading, data }] = useLazyGetDashboardSalesQuery();
  const { data: ordersData, isLoading: ordersLoading, error: ordersError } = useGetAdminOrdersQuery();

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message || "An error occurred");
    }
    if (ordersError) {
      toast.error(ordersError?.data?.message || "An error occurred");
    }
    if (startDate && endDate && !data) {
      getDashboardSales({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });
    }
  }, [startDate, endDate, error, ordersError, getDashboardSales, data]);

  const submitHandler = () => {
    getDashboardSales({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
    }).format(new Date(date));
};

// 7-day order tracking
const today = new Date();
today.setUTCHours(0, 0, 0, 0); // Set today's time to 00:00:00 in UTC

const ordersByDay = [];
for (let i = 0; i < 7; i++) {
    const currentDay = new Date(today.getTime()); // Clone today's date
    currentDay.setUTCDate(today.getUTCDate() + i); // Adjust date in UTC
    currentDay.setUTCHours(0, 0, 0, 0); // Set time to midnight UTC

    const dailyOrders = ordersData?.order?.filter(order => {
        const deliveryDate = new Date(order?.shippingInfo?.deliveryDate);
        deliveryDate.setUTCHours(0, 0, 0, 0); // Normalize delivery date to UTC midnight

        // Compare normalized UTC dates
        return deliveryDate.getTime() === currentDay.getTime();
    });

    ordersByDay.push({ date: currentDay, orders: dailyOrders });
}

  const generatePDF = (orders, date, type) => {
    const pdfWindow = window.open("", "_blank");
    pdfWindow.document.write(`
      <html>
        <head>
          <title>${type} Orders for ${date.toDateString()}</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            table, th, td {
              border: 1px solid black;
            }
            th, td {
              padding: 8px;
              text-align: left;
            }
            .fixed-width {
              width: 150px;
              word-wrap: break-word;
            }
          </style>
        </head>
        <body>
          <h1>${type} Orders for ${date.toDateString()}</h1>
          ${orders.map(order => `
            <h3>Order ID: ${order.pid}</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Item Name</th>
                  <th>Recipe Code</th>
                  <th>Size</th>
                  <th>Additional Note</th>
                  <th>Quantity</th>
                  <th>Cake Baked By</th>
                  <th>Cake Baked At</th>
                  <th>Delivery Time</th>
                  <th>Delivery Date</th>
                  <th>Delivery Address</th>
                  <th>City</th>
                  <th>Postal Code</th>
                  <th>Phone</th>
                  <th>Cake Delivered By</th>
                  <th>Cake Delivered At</th>
                </tr>
              </thead>
              <tbody>
                ${order.orderItems.filter(item => item.name !== "Shipping").map(item => `
                  <tr>
                    <td>${order.pid}</td>
                    <td>${item.name || "N/A"}</td>
                    <td>${item.recipecode || "N/A"}</td>
                    <td>${item.size || "N/A"}</td>
                    <td>${item.Addnotetocake || ""}</td>
                    <td>${item.quantity || "N/A"}</td>
                    <td>${order.cakeBakedBy || ""}</td>
                    <td>${order.cakeBakedAt || ""}</td>
                    <td>${order?.shippingInfo?.time || ""}</td>
                    <td>${order.shippingInfo.deliveryDate || "N/A"}</td>
                    <td>${order.shippingInfo.address || "N/A"}</td>
                    <td>${order.shippingInfo.city || "N/A"}</td>
                    <td>${order.shippingInfo.postalCode || "N/A"}</td>
                    <td>${order.shippingInfo.phoneNo  || "Contact Admin"}</td>
                    <td>${order.cakeDeliveredBy || ""}</td>
                    <td>${order.CakeDeliveredAt || ""}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          `).join('')}
        </body>
      </html>
    `);
    pdfWindow.document.close();
    pdfWindow.print();
  };

  const generateDriverPDF = (orders, date) => {
    const pdfWindow = window.open("", "_blank");
    pdfWindow.document.write(`
      <html>
        <head>
          <title>Driver Delivery Orders for ${date.toDateString()}</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            table, th, td {
              border: 1px solid black;
            }
            th, td {
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>
          <h1>Driver Delivery Orders for ${date.toDateString()}</h1>
          <table>
            <thead>
              <tr>
                <th>Driver Delivery Date ID</th>
                <th>Delivery Address</th>
                <th>City</th>
                <th>Postal Code</th>
                <th>Phone</th>
                <th>Cake Delivered By</th>
                <th>Cake Delivered At</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              ${orders.map(order => `
                <tr>
                  <td>${order.pid}</td>
                  <td>${order.shippingInfo.address || "N/A"}</td>
                  <td>${order.shippingInfo.city || "N/A"}</td>
                  <td>${order.shippingInfo.postalCode || "N/A"}</td>
                  <td>${order.shippingInfo.phoneNo || "Contact Admin"}</td>
                  <td>${order.cakeDeliveredBy || ""}</td>
                  <td>${order.CakeDeliveredAt || ""}</td>
                  
                </tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `);
    pdfWindow.document.close();
    pdfWindow.print();
  };
  
  const generateBakerPDF = (orders, date) => {
    const pdfWindow = window.open("", "_blank");
    pdfWindow.document.write(`
      <html>
        <head>
          <title>Baker Details Orders for ${date.toDateString()}</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            table, th, td {
              border: 1px solid black;
            }
            th, td {
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>
          <h1>Baker Details Orders for ${date.toDateString()}</h1>
          <table>
            <thead>
              <tr>
                <th>Baker ID</th>
                <th>Item Name</th>
                <th>Recipe Code</th>
                <th>Size</th>
                <th>Additional Note</th>
                <th>Quantity</th>
                <th>Cake Baked By</th>
                <th>Cake Baked At</th>
              </tr>
            </thead>
            <tbody>
              ${orders.map(order => order.orderItems.map(item => `
                <tr>
                  <td>${order.pid}</td>
                  <td>${item.name || "N/A"}</td>
                  <td>${item.recipecode || "N/A"}</td>
                  <td>${item.size || "N/A"}</td>
                  <td>${item.Addnotetocake || ""}</td>
                  <td>${item.quantity || "N/A"}</td>
                  <td>${order.cakeBakedBy || ""}</td>
                  <td>${order.cakeBakedAt || ""}</td>
                </tr>
              `).join('')).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `);
    pdfWindow.document.close();
    pdfWindow.print();
  };
  

  const generatePickUpPDF = (orders, date) => generatePDF(orders, date, 'Pick Up');
  const generateDeliveryPDF = (orders, date) => generatePDF(orders, date, 'Delivery');

  if (isLoading || ordersLoading) return <Loader />;

  return (
    <AdminLayout>
      <MetaData title={"Admin Dashboard"} />
      <div className="d-flex justify-content-start align-items-center">
        <div className="mb-3 me-4">
          <label className="form-label d-block">Start Date</label>
          <input
            type="date"
            id="start_date_field"
            className="form-control"
            name="startDate"
            value={startDate.toISOString().substr(0, 10)}
            onChange={(e) => setStartDate(new Date(e.target.value))}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label d-block">End Date</label>
          <input
            type="date"
            id="end_date_field"
            className="form-control"
            name="endDate"
            value={endDate.toISOString().substr(0, 10)}
            onChange={(e) => setEndDate(new Date(e.target.value))}
            required
          />
        </div>
        <button className="btn fetch-btn ms-4 mt-3 px-5" onClick={submitHandler}>Fetch</button>
      </div>

      <div className="row pr-4 my-5">
        <div className="col-xl-6 col-sm-12 mb-3">
          <div className="card text-white bg-success o-hidden h-100">
            <div className="card-body">
              <div className="text-center card-font-size">
                Sales
                <br />
                <b>${data?.totalSales?.toFixed(2)}</b>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-sm-12 mb-3">
          <div className="card text-white bg-danger o-hidden h-100">
            <div className="card-body">
              <div className="text-center card-font-size">
                Orders
                <br />
                <b>{data?.totalNumOrders}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SalesChart salesData={data?.sales} />

      <div className="future-orders-section my-5">
        <h3>Our Daily Orders</h3>
        {ordersByDay.map(({ date, orders }, index) => {
          const pickUpOrders = orders?.filter(order =>
            ["8028 128 St Unit 104, Surrey, BC", "2610 Cedar Park Pl, Abbotsford, BC"].includes(order.shippingInfo.address)
          );

          const deliveryOrders = orders?.filter(order =>
            !["8028 128 St Unit 104, Surrey, BC", "2610 Cedar Park Pl, Abbotsford, BC"].includes(order.shippingInfo.address)
          );

          return (
            <div key={index} className="mb-4">
              <h4>{formatDate(date)}</h4>
              <div className="mb-3">
                <button
                  className="btn btn-primary mb-3"
                  onClick={() => generatePickUpPDF(pickUpOrders, date)}
                  disabled={!pickUpOrders || pickUpOrders.length === 0}
                >
                  Download Pick Up Orders as PDF
                </button>
                <button
                  className="btn btn-primary mb-3 ms-3"
                  onClick={() => generateDeliveryPDF(deliveryOrders, date)}
                  disabled={!deliveryOrders || deliveryOrders.length === 0}
                >
                  Download Delivery Orders as PDF
                </button>
                <button
                  className="btn btn-primary mb-3 ms-3"
                  onClick={() => generateBakerPDF(pickUpOrders, date)}
                  disabled={!pickUpOrders || pickUpOrders.length === 0}
                >
                  Baker Details as PDF
                </button>
                
                <button
                  className="btn btn-primary mb-3 ms-3"
                  onClick={() => generateDriverPDF(deliveryOrders, date)}
                  disabled={!deliveryOrders || deliveryOrders.length === 0}
                >
                Driver Delivery Orders as PDF
                </button>
                
              </div>

              <div className="table-responsive">
                <h5>Pick Up Orders</h5>
                {(!pickUpOrders || pickUpOrders.length === 0) && (
                  <div className="alert alert-info">No pickup orders for this day.</div>
                )}
                {pickUpOrders && pickUpOrders.length > 0 && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Item Name</th>
                        <th>Recipe Code</th>
                        <th>Size</th>
                        <th>Additional Note</th>
                        <th>Quantity</th>
                        <th>Cake Baked By</th>
                        <th>Cake Baked At</th>
                        <th>Delivery Time</th>
                        <th>Delivery Date</th>
                        <th>Delivery Address</th>
                        <th>City</th>
                        <th>Postal Code</th>
                        <th>Phone</th>
                        <th>Cake Delivered By</th>
                        <th>Cake Delivered At</th>
                        <th>Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pickUpOrders.map(order =>
                        order.orderItems.filter(item => item.name !== "Shipping").map(item => (
                          <tr key={item._id}>
                            <td>{order.pid}</td>
                            <td>{item.name || "N/A"}</td>
                            <td>{item.recipecode || "N/A"}</td>
                            <td>{item.size || "N/A"}</td>
                            <td>{item.Addnotetocake || ""}</td>
                            <td>{item.quantity || "N/A"}</td>
                            <td>{order.cakeBakedBy || ""}</td>
                            <td>{order.cakeBakedAt || ""}</td>
                            <td>{order?.shippingInfo?.time || ""}</td>
                            <td>{order.shippingInfo.deliveryDate }</td>
                            <td>{order.shippingInfo.address || "N/A"}</td>
                            <td>{order.shippingInfo.city || "N/A"}</td>
                            <td>{order.shippingInfo.postalCode || "N/A"}</td>
                            <td>{order.shippingInfo.phoneNo  || "Contact Admin"}</td>
                            <td>{order.cakeDeliveredBy || ""}</td>
                            <td>{order.CakeDeliveredAt || ""}</td>
                            <td>
                            <Link to={`/admin/orders/${order?._id}`} className="btn btn-outline-primary me-2">
                                        <i className="fa fa-pencil"></i>
                            </Link>
                          </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                )}
              </div>

              <div className="table-responsive">
                <h5>Delivery Orders</h5>
                {(!deliveryOrders || deliveryOrders.length === 0) ? (
                  <div className="alert alert-info">No delivery orders for this day.</div>
                ) : (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Item Name</th>
                        <th>Recipe Code</th>
                        <th>Size</th>
                        <th>Additional Note</th>
                        <th>Quantity</th>
                        <th>Cake Baked By</th>
                        <th>Cake Baked At</th>
                        <th>Delivery Time</th>
                        <th>Delivery Date</th>
                        <th>Delivery Address</th>
                        <th>City</th>
                        <th>Postal Code</th>
                        <th>Phone</th>
                        <th>Cake Delivered By</th>
                        <th>Cake Delivered At</th>
                        <th>Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deliveryOrders.map(order =>
                        order.orderItems.filter(item => item.name !== "Shipping").map(item => (
                          <tr key={item._id}>
                            <td>{order.pid}</td>
                            <td>{item.name || "N/A"}</td>
                            <td>{item.recipecode || "N/A"}</td>
                            <td>{item.size || "N/A"}</td>
                            <td>{item.Addnotetocake || ""}</td>
                            <td>{item.quantity || "N/A"}</td>
                            <td>{order.cakeBakedBy || ""}</td>
                            <td>{order.cakeBakedAt || ""}</td>
                            <td>{order?.shippingInfo?.time || ""}</td>
                            <td>{order.shippingInfo.deliveryDate }</td>
                            <td>{order.shippingInfo.address || "N/A"}</td>
                            <td>{order.shippingInfo.city || "N/A"}</td>
                            <td>{order.shippingInfo.postalCode || "N/A"}</td>
                            <td>{order.shippingInfo.phoneNo || "Contact Admin"}</td>
                            <td>{order.cakeDeliveredBy || ""}</td>
                            <td>{order.CakeDeliveredAt || ""}</td>
                            <td>
                            <Link to={`/admin/orders/${order?._id}`} className="btn btn-outline-primary me-2">
                                        <i className="fa fa-pencil"></i>
                            </Link>
                          </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </AdminLayout>
  );
};

export default Dashboard;