import React, { useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import Search from './Search';
import { useGetMeQuery } from '../../redux/api/userApi';
import { useLazyLogoutQuery } from '../../redux/api/authApi';
import logoImage from "../layout/images/c.png";
import "../test/header.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading } = useGetMeQuery();
  const [logout] = useLazyLogoutQuery();
  const { user } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);
  const navbarToggler = useRef(null);
  const navbarCollapse = useRef(null);

  const logoutHandler = async () => {
    try {
      await logout().unwrap();
      navigate(0);  // Refresh the page
    } catch (error) {
      console.error("Logout failed: ", error);
    }
  };

  const ensureHttps = (url) => {
    return url ? url.replace(/^http:\/\//i, 'https://') : url;
  };

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  const handleLinkClick = (event, target) => {
    event.preventDefault();

    // Close the mobile menu if it's open
    if (navbarCollapse.current.classList.contains('show')) {
      navbarToggler.current.click();
    }

    // Use setTimeout to ensure the menu closing animation completes before scrolling
    setTimeout(() => {
      const element = document.getElementById(target);
      if (element) {
        const headerOffset = 60; // Adjust this value based on your header's height
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      } else {
        navigate(target);
      }
    }, 350); // Adjust this delay if needed to match your menu closing animation duration
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Helmet>
        <meta property="og:image" content="../images/c.png" />
        <meta property="og:url" content="https://veggiecakes.ca" />
        <meta property="og:title" content="Veggie Cakes" />
        <meta property="og:description" content="Welcome to Vegetarian Delite Bakery, your Vancouver local destination for delicious and healthy cakes! Try our veggie cakes." />
      </Helmet>
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" ref={navbarToggler}>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown" ref={navbarCollapse}>
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link to="/home" className="nav-link" onClick={(e) => handleLinkClick(e, 'home')}>Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/ourcakes" className="nav-link" onClick={(e) => handleLinkClick(e, 'ourcakes')}>Our Cakes</Link>
            </li>
            <li className="nav-item">
              <Link to="/aboutus" className="nav-link" onClick={(e) => handleLinkClick(e, 'aboutus')}>About Us</Link>
            </li>
            <li className="nav-item">
              <Link to="/faq" className="nav-link" onClick={(e) => handleLinkClick(e, 'faq')}>FAQ</Link>
            </li>
            <li className="nav-item">
              <Link to="/delivery" className="nav-link" onClick={(e) => handleLinkClick(e, 'delivery')}>Delivery Service</Link>
            </li>
            <li className="nav-item">
              <Search />
            </li>
            <li className="nav-item">
              <Link to="/cart" className="nav-link" onClick={(e) => handleLinkClick(e, 'cart')}>
                <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                <span id="cart_count">{cartItems?.length}</span>
              </Link>
            </li>
            <li className="nav-item">
              {user ? (
                <div className="user-menu">
                  <button className="dropdown-toggle nav-link" type="button" data-bs-toggle="dropdown">
                    <img src={ensureHttps(user?.avatar ? user?.avatar?.url : "../images/default_avatar.jpg")} alt="User  Avatar" className="rounded-circle avatar-nav" />
                    <span>{user?.name}</span>
                  </button>
                  <div className="dropdown-menu">
                    {user?.role === 'admin' && (
                      <Link to="/admin/dashboard" className="dropdown-item" onClick={(e) => handleLinkClick(e, 'admin/dashboard')}>Dashboard</Link>
                    )}
                    <Link to="/me/orders" className="dropdown-item" onClick={(e) => handleLinkClick(e, 'me/orders')}>Orders</Link>
                    <Link to="/me/profile" className="dropdown-item" onClick={(e) => handleLinkClick(e, 'me/profile')}>Profile</Link>
                    <Link to="/" className="dropdown-item text-danger" onClick={logoutHandler}>Logout</Link>
                  </div>
                </div>
              ) : (
                !isLoading && (
                  <Link to="/login" className="nav-link" onClick={(e) => handleLinkClick(e, 'login')}>Login</Link>
                )
              )}
            </li>
          </ul>
        </div>
        <Link to="/cart" className="navbar-brand cart-icon" onClick={(e) => handleLinkClick(e, 'cart')}>
          <FontAwesomeIcon icon={faShoppingCart} size="lg" />
          <span id="cart_count">{cartItems?.length}</span>
        </Link>
      </div>
    </nav>
  );
};

export default Header;