import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useGetProductDetailsQuery } from "../../redux/api/productsApi";
import toast from 'react-hot-toast';
import Loader from '../layout/Loader';
import StarRatings from "react-star-ratings";
import { useDispatch, useSelector } from 'react-redux';
import { setCartItem } from '../../redux/features/cartSlice';
import MetaData from "../layout/MetaData";
import NewReview from '../reviews/NewReview';
import ListReviews from '../reviews/ListReviews';
import { calculateCakeCost } from '../../helpers/cakeCal';
import "../../App.css"
import NotFound from '../layout/NotFound';

const Breadcrumb = ({ currentStep, handleStepChange }) => {
  const steps = ['Size Selection', 'Flavour Selection', 'Base Selection', 'Add a Note'];

  return (
    <div className="breadcrumb-container">
      <nav 
        aria-label="breadcrumb" 
        className="breadcrumb-nav"
      >
        <div className="breadcrumb-title">
          <h5>Your cake is a few steps away</h5>
        </div>
        <div className="breadcrumb-steps">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className={`step-item ${currentStep === index + 1 ? 'active' : ''}`}
            >
              <div 
                className={`step-text ${currentStep === index + 1 ? 'current' : ''}`}
              >
                {currentStep === index + 1 ? (
                  <span className="current-step">{step}</span>
                ) : (
                  <>
                    <span className="step-arrow">→</span>
                    {step}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </nav>
    </div>
  );
};

const ProductDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [activeImg, setActiveImg] = useState('');
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedFlavour, setSelectedFlavour] = useState(null);
  const [selectedBase, setSelectedBase] = useState(null);
  const [noteToCake, setNoteToCake] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [price, setPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const { data, isLoading, error, isError } = useGetProductDetailsQuery(params?.id);
  const product = data?.product;
  const { isAuthenticated } = useSelector((state) => state.auth);

  // Size images mapping
  const sizeImages = {
    sizeSmall: "../images/6r.jpg",
    sizeMedium: "../images/6t.jpg",
    sizeQuarterSlab: "../images/8r.jpg",
    sizeHalfSlab: "../images/8t.jpg",
    sizeFullSlab: "../images/vz4.jpg",
    serv1: "../images/vz5.jpg",
    serv2: "../images/vz6.jpg",
    serv3: "https://storage.googleapis.com/a1aa/image/TO792keCVCXWBaha2sFCATkcOqMWI5xr23OuE4BajWe1WGrTA.jpg",
    serv4: "https://storage.googleapis.com/a1aa/image/Wm2D98ciq96NMxXGeckFyF6fvmkPvb88BVPnou6ZXbePgjRnA.jpg"
  };

  // Flavour images mapping
  const flavourImages = {
    flavourChocolate: "https://storage.googleapis.com/a1aa/image/UqbJZ6lH9MJ3AFSdiTngfiaDweCRlOXEUvKisI3T1hBFPGrTA.jpg",
    flavourBlackForest: "https://storage.googleapis.com/a1aa/image/6jJd9qyR13LOPt0giKHCcZbgLMmL2lG0VoxJRCWzeDMiHj1JA.jpg",
    flavourRedVelvet: "../images/red.jpg",
    flavourFruit: "https://storage.googleapis.com/a1aa/image/PqTX1RrDFdL1NxqkcjZKXGRqykT4g6OXTk6Lszeo5fK8TGrTA.jpg",
    flavourVanilla: "https://storage.googleapis.com/a1aa/image/ujUMpdE9tQ5dFR8Adcq1q7T5BKQRhmRnKJoG1bXJrUWyjx6E.jpg",
    flavourPineapple: "https://storage.googleapis.com/a1aa/image/Z1yVvm7cfBXxbSG7D7Xp2ebzh5JUtDZbeiiEUVSgIfCi8YsOB.jpg",
    flavourMango: "../images/am1.jpg",
    flavourStrawberry: "../images/am2.jpg"
  };

  const sizeLabels = {
    sizeSmall: '6" - Round (servings 4-6 ppl)',
    sizeMedium: '6" - Tall Round (servings 10-12)',
    sizeQuarterSlab: '8" - Round (servings 10-12)',
    sizeHalfSlab: '8" - Tall Round (servings 18-20)',
    sizeFullSlab: 'Quarter - 8"x12" (servings 18-20)',
    serv1: 'Half Slab - 12"x15" (servings 45-55)',
    serv2: 'Full Slab - 16"x24 (servings 90-100)',
    serv3: 'Large Party (10-15 ppl)',
    serv4: 'Event Size (20-25 ppl)'
  };

  const ensureHttps = (url) => {
    return url ? url.replace(/^http:\/\//i, 'https://') : url;
  };

  const handleSelect = (category, label) => {
    switch (category) {
      case 'size':
        setSelectedSize(label);
        break;
      case 'flavour':
        setSelectedFlavour(label);
        break;
      case 'base':
        setSelectedBase(label);
        break;
      default:
        break;
    }
  };
  

  const handleNextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(prevStep => prevStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const isNextDisabled = () => {
    if (currentStep === 1) return !selectedSize;
    if (currentStep === 2) return !selectedFlavour;
    if (currentStep === 3) return !selectedBase;
    return false;
  };

  const updateNoteToCake = () => {
    const selections = {
      size: selectedSize?.replace('size', '').replace('serv', 'Serving '),
      base: selectedBase?.replace('base', ''),
      flavour: selectedFlavour?.replace('flavour', ''),
    };
    
    const selectedText = `\nSelected options: Size - ${selections.size || ''}, Flavour - ${selections.flavour || ''}, Base - ${selections.base || ''}`;
    return noteToCake + selectedText;
  };

  const handleCustomNoteChange = (e) => {
    setNoteToCake(e.target.value);
  };

  const setItemToCart = () => {
    const finalNoteToCake = updateNoteToCake();

    const selectedSizeLabel = sizeLabels[selectedSize];

    const cartItem = {
        product: product?._id,
        name: product?.name,
        price: total,
        image: product?.images[0] ? ensureHttps(product.images[0].url) : "/images/default_product.png",
        flavour: selectedFlavour?.replace('flavour', ''),
        size: selectedSizeLabel || '',
        base: selectedBase?.replace('base', ''),
        Addnotetocake: finalNoteToCake,
        colour: product?.colour,
        recipecode : product?.recipecode,
        quantity: quantity,
    };

    dispatch(setCartItem(cartItem));
    toast.success("Item added to cart");
  };

 // Modify the total calculation to handle undefined values
const total = (
  (selectedSize && product?.[selectedSize] ? Number(product[selectedSize]) : 0) +
  (selectedFlavour && product?.[selectedFlavour] ? Number(product[selectedFlavour]) : 0) +
  (selectedBase && product?.[selectedBase] ? Number(product[selectedBase]) : 0)
).toFixed(2); // Format to 2 decimal places

  useEffect(() => {
    if (product?.images?.length > 0) {
      setActiveImg(ensureHttps(product.images[0]?.url) || "/images/default_product.png");
    }
  }, [product]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message || "An error occurred while fetching products.");
    }
  }, [isError, error]);

  useEffect(() => {
    if (product && selectedSize) {
      const calculatedPrice = calculateCakeCost(product?.category, product?.recipecode, selectedSize);
      setPrice(calculatedPrice);
    }
  }, [product, selectedSize]);

  const increaseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber >= product?.stock) return;
    setQuantity(count.valueAsNumber + 1);
  };

  const decreaseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber <= 1) return;
    setQuantity(count.valueAsNumber - 1);
  };

  const handleAgree = () => {
    setAgreed(true);
    setShowModal(false);
    setItemToCart();
    window.location.href = 'https://veggiecakes.ca/cart';
  };

  const handleDisagree = () => {
    setAgreed(false);
    setShowModal(false);
  };

  if (isLoading) return <Loader />;
  if (error && error?.status == 404) return <NotFound />;

  return (
    <>
      <MetaData title={product.name} />
      <div className="row d-flex justify-content-around">
        <div className="col-12 col-lg-5 img-fluid" id="product_image">
          <div className="p-3">
            <img
              className="d-block w-100"
              src={activeImg || "/images/default_product.png"}
              alt={product?.name || "Product"}
              width="340"
              height="390"
            />
          </div>
          <div className="row justify-content-start mt-5">
            {product?.images?.map((image, index) => (
              <div className="col-3 ms-2 mt-2" key={index}>
                <button
                  className={`d-block p-1 cursor-pointer ${ensureHttps(image.url) === activeImg ? "border-warning" : ""}`}
                  onClick={() => setActiveImg(ensureHttps(image.url))}
                  style={{ border: '3px solid', padding: '0' }}
                >
                  <img
                    className="d-block"
                    height="100"
                    width="100"
                    src={ensureHttps(image.url) || "/images/default_product.png"}
                    alt={product?.name || "Thumbnail"}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="col-12 col-lg-5 mt-5">
          <h3>{product?.name}</h3>
          <hr />
          <div className="d-flex">
            <div className="star-ratings">
              <StarRatings
                rating={product?.ratings}
                starRatedColor="#ffb829"
                numberOfStars={5}
                name="rating"
                starDimension="22px"
                starSpacing="1px"
              />
            </div>
            <span id="no-of-reviews" className="pt-1 ps-2">({product?.numberOfReviews} Reviews)</span>
          </div>
          <hr />
          <p id="product_price">${total}</p>

          <div style={{ marginBottom: "20px" }}>
    <Breadcrumb currentStep={currentStep} handleStepChange={handleStepChange} />
  </div>

  {currentStep === 1 && (
  <div className="size-selection mt-3">
    <label className="form-label" style={{ fontWeight: 'bold' }}>Select Size:</label>
    <div className="d-flex flex-wrap gap-3">
      {['sizeSmall', 'sizeMedium', 'sizeQuarterSlab', 'sizeHalfSlab', 'sizeFullSlab', 'serv1', 'serv2', 'serv3', 'serv4'].map((size) => {
        // Only show sizes that have a valid price/availability
        const sizeAvailable = product[size] !== 0 && product[size] !== "";
        
        return sizeAvailable ? (
          <div
            key={size}
            className="size-option"
            style={{ 
              width: '120px'
            }}
          >
            <button 
              className="btn position-relative p-0"
              onClick={() => handleSelect('size', size)}
              style={{ 
                width: '100%', 
                aspectRatio: '1',
                border: `2px solid ${selectedSize === size ? '#ec4899' : '#f9a8d4'}`,
                backgroundColor: selectedSize === size ? '#fdf2f8' : 'transparent'
              }}
            >
              <img 
                src={sizeImages[size] || "/images/default_size.jpg"}
                alt={sizeLabels[size]}
                className="w-100 h-100"
              />
            </button>
            <p className="text-center mb-0 mt-1" style={{ fontSize: '0.8rem' }}>
              {sizeLabels[size]}
            </p>
          </div>
        ) : null;
      })}
    </div>
    <div className="mt-3 text-center">
      {!selectedSize && (
        <p className="text-danger mb-2" style={{ fontSize: '0.8rem' }}>
          Please select a size to continue
        </p>
      )}
      <button 
        className="btn" 
        style={{
          backgroundColor: !selectedSize ? '#fce7f3' : '#ec4899',
          color: !selectedSize ? '#9d174d' : 'white',
          border: 'none'
        }}
        onClick={handleNextStep} 
        disabled={!selectedSize}
      >
        Next
      </button>
    </div>
  </div>
)}

{currentStep === 2 && (
  <div className="flavour-selection mt-3">
    <label className="form-label" style={{ fontWeight: 'bold' }}>Select Flavour:</label>
    <div className="d-flex flex-wrap gap-3">
      {['flavourChocolate', 'flavourBlackForest', 'flavourRedVelvet', 'flavourFruit', 'flavourVanilla', 'flavourPineapple', 'flavourMango', 'flavourStrawberry'].map((flavour) => {
        // Only show flavors that have a valid price/availability
        const flavourAvailable = product[flavour] !== 0 && product[flavour] !== "";
        
        return flavourAvailable ? (
          <div
            key={flavour}
            className="flavour-option"
            style={{ 
              width: '120px'
            }}
          >
            <button 
              className="btn position-relative p-0"
              onClick={() => handleSelect('flavour', flavour)}
              style={{ 
                width: '100%', 
                aspectRatio: '1',
                border: `2px solid ${selectedFlavour === flavour ? '#ec4899' : '#f9a8d4'}`,
                backgroundColor: selectedFlavour === flavour ? '#fdf2f8' : 'transparent'
              }}
            >
              <img 
                src={flavourImages[flavour] || "/images/default_flavour.jpg"}
                alt={flavour}
                className="w-100 h-100 object-fit-cover"
              />
            </button>
            <p className="text-center mb-0 mt-1" style={{ fontSize: '0.8rem' }}>
              {flavour.replace('flavour', '')}
            </p>
          </div>
        ) : null;
      })}
    </div>
    <div className="mt-3">
      <button 
        className="btn" 
        style={{
          backgroundColor: '#ec4899',
          color: 'white',
          border: 'none',
          marginRight: '8px'
        }}
        onClick={handlePreviousStep}
      >
        Previous
      </button>
      <div className="d-inline-block">
        {!selectedFlavour && (
          <p className="text-danger mb-2" style={{ fontSize: '0.8rem' }}>
            Please select a flavor to continue
          </p>
        )}
        <button 
          className="btn" 
          style={{
            backgroundColor: !selectedFlavour ? '#fce7f3' : '#ec4899',
            color: !selectedFlavour ? '#9d174d' : 'white',
            border: 'none'
          }}
          onClick={handleNextStep} 
          disabled={!selectedFlavour}
        >
          Next
        </button>
      </div>
    </div>
  </div>
)}

{currentStep === 3 && (
  <div className="base-selection mt-3">
    <label className="form-label" style={{ fontWeight: 'bold' }}>Select Base:</label>
    <div className="btn-group" role="group" aria-label="Base selection">
      {['baseRegular', 'baseGlutenFree', 'baseVeganGlutenFree'].map((base) => {
        // Only show if the base has a valid price
        const basePrice = Number(product?.[base]);
        const shouldDisplay = !isNaN(basePrice) && basePrice >= 0;
        
        return shouldDisplay ? (
          <button 
            key={base}
            className="btn selection-btn"
            onClick={() => handleSelect('base', base)}
            style={{ 
              backgroundColor: selectedBase === base ? '#ec4899' : '#fce7f3',
              color: selectedBase === base ? 'white' : '#9d174d',
              border: 'none',
              margin: '0 4px'
            }}
          >
            {base.replace('base', '').replace(/([A-Z])/g, ' $1').trim()}
          </button>
        ) : null;
      })}
    </div>
    <div className="mt-3">
      <button 
        className="btn" 
        style={{
          backgroundColor: '#ec4899',
          color: 'white',
          border: 'none',
          marginRight: '8px'
        }}
        onClick={handlePreviousStep}
      >
        Previous
      </button>
      <div className="d-inline-block">
        {!selectedBase && (
          <p className="text-danger mb-2" style={{ fontSize: '0.8rem' }}>
            Please select a base to continue
          </p>
        )}
        <button 
          className="btn" 
          style={{
            backgroundColor: !selectedBase ? '#fce7f3' : '#ec4899',
            color: !selectedBase ? '#9d174d' : 'white',
            border: 'none'
          }}
          onClick={handleNextStep} 
          disabled={!selectedBase}
        >
          Next
        </button>
      </div>
    </div>
  </div>
)}

{currentStep === 4 && (
  <div className="form-group mt-3">
    <label className="form-label" style={{ fontWeight: 'bold' }}>Add a note to your cake:</label>
    <textarea
      id="customNote"
      className="form-control"
      value={noteToCake}
      onChange={handleCustomNoteChange}
      rows="3"
      placeholder="Write your custom note here"
    ></textarea>
    <div className="mt-3">
      <button 
        className="btn" 
        style={{
          backgroundColor: '#ec4899',
          color: 'white',
          border: 'none',
          marginRight: '8px'
        }}
        onClick={handlePreviousStep}
      >
        Previous
      </button>
      <button 
        className="btn" 
        style={{
          backgroundColor: '#ec4899',
          color: 'white',
          border: 'none'
        }}
        onClick={() => {
          // Check if the total price is greater than zero
          if (total > 0) {
            setShowModal(true); // Show the modal if total price is valid
           
          } else {
            alert("The total price is zero. Please select valid options."); // Show an alert if total is zero
          }
        }}
      >
        Add to Cart
      </button>
    </div>
  </div>
)}

          <div className="stockCounter d-inline">
            <span className="btn btn-danger minus" onClick={decreaseQty}>-</span>
            <input
              type="number"
              className="form-control count d-inline"
              value={quantity}
              readOnly
            />
            <span className="btn btn-primary plus" onClick={increaseQty}>+</span>
          </div>

          <hr />

          <p>
            Order: <span id="stock_status">In Store Or Delivery</span>
          </p>

          <hr />

          <h4 className="mt-2">Description:</h4>
          <p>{product?.description}</p>
          <hr />

          {isAuthenticated ? (
            <NewReview productId={product?._id} />
          ) : (
            <div className="alert alert-danger my-5" role="alert">
              Login to post your review.
            </div>
          )}
        </div>
      </div>

      {product?.reviews?.length > 0 && <ListReviews reviews={product?.reviews} />}

      {/* Modal for Terms and Conditions */}
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} id="termsModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Cake Order Terms and Conditions</h5>
                <button type="button" className="btn-close" onClick={handleDisagree}></button>
              </div>
              <div className="modal-body">
                <p>By ordering a cake, you agree to the following policies regarding cake design:</p>
                <ul>
                  <li>Please avoid pushing anyone's face into the cake to prevent serious injury from dowel rods, decorations, or other elements.</li>
                  <li>All tiered and high cakes contain food-safe dowel rods; please remove them before serving.</li>
                  <li>Since cakes are handmade, designs may vary from pictures.</li>
                  <li>Colors on screen may differ from actual colors due to screen types and lighting.</li>
                  <li>We strive to match colors as closely as possible but can't guarantee an exact match.</li>
                  <li>Cakes with strawberries should be consumed on the same day due to their limited shelf life.</li>
                  <li>Decorations on cakes may not always be available; we will use the closest replacement if necessary.</li>
                  <li>Dark colors like black, blue, and red may taste bitter and stain; consider choosing lighter colors or removing dark-colored cream.</li>
                  <li>All cake decorations are food-safe but not necessarily edible; please remove them before eating and supervise small children.</li>
                </ul>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleDisagree}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleAgree}>I Agree</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;