import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Loader from "../layout/Loader";
import MetaData from "../layout/MetaData";
import AdminLayout from "../layout/AdminLayout";
import { useGetStoreQuery, useUpdateStoreMutation } from "../../redux/api/storeApi";

const EditStore = () => {
    const params = useParams();
    console.log('Store ID from params:', params.id); // Log the store ID

    const { data, isLoading: isLoadingStore, error: storeError } = useGetStoreQuery(params?.id);
    console.log('Fetched store data:', data); // Log the fetched data

    const [updateStore, { error: updateError, isSuccess }] = useUpdateStoreMutation();

    // State variables for store details
    const [storeDetails, setStoreDetails] = useState({
        staffname: '',
        price: '',
        name: '',
        email: '',
        address: '',
        phone: '',
        city: '',
        po: '',
        date: '',
        time: '',
        cakefl: '',
        cakeno: '',
        message: '',
        payment: '',
        pick: ''
    });

    // Populate form with existing store data
    useEffect(() => {
        if (data && data.success && data.data) { // Check if data is valid
            const store = data.data; // Adjust based on your actual data structure
            setStoreDetails({
                staffname: store.staffname || '',
                price: store.price || '',
                name: store.name || '',
                email: store.email || '',
                address: store.address || '',
                phone: store.phone || '',
                city: store.city || '',
                po: store.po || '',
                date: store.date || '',
                time: store.time || '',
                cakefl: store.cakefl || '',
                cakeno: store.cakeno || '',
                message: store.message || '',
                payment: store.payment || '',
                pick: store.pick || ''
            });
        } else {
            console.log('No store data found or data structure is unexpected.'); // Log if no data is found
        }
    }, [data]);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStoreDetails(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle errors and success
    useEffect(() => {
        if (storeError) {
            console.error('Error fetching store:', storeError); // Log the error for debugging
            toast.error(storeError?.data?.message || 'Failed to fetch store data');
        }
        if (updateError) {
            console.error('Error updating store:', updateError); // Log the update error
            toast.error(updateError?.data?.message || 'Failed to update store');
        }
        if (isSuccess) {
            toast.success("Store updated successfully");
        }
    }, [storeError, updateError, isSuccess]);

    // Update store handler
    const updateStoreHandler = () => {
        console.log('Updating store with data:', storeDetails); // Log the data being sent
        const updateData = { ...storeDetails };
        updateStore({ 
            id: params?.id, 
            body: updateData 
        });
    };

    // Show loader while fetching data
    if (isLoadingStore) {
        return <Loader />;
    }

    return (
        <AdminLayout>
            <MetaData title="Edit Store" />

            <div className="row">
                <div className="col-12 col-lg-10 mx-auto">
                    <form>
                        <h2 className="mb-4">Edit Store Details</h2>

                        {/* Staff Name */}
                        <div className="mb-3">
                            <label className="form-label">Staff Name</label>
                            <input
                                type="text"
                                name="staffname"
                                className="form-control"
                                value={storeDetails.staffname}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Price */}
                        <div className="mb-3">
                            <label className="form-label">Price</label>
                            <input
                                type="text"
                                name="price"
                                className="form-control"
                                value={storeDetails.price}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Name */}
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={storeDetails.name}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Email */}
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                value={storeDetails.email}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Address */}
                        <div className="mb-3">
                            <label className="form-label">Address</label>
                            <input
                                type="text"
                                name="address"
                                className="form-control"
                                value={storeDetails.address}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Phone */}
                        <div className="mb-3">
                            <label className="form-label">Phone</label>
                            <input
                                type="tel"
                                name="phone"
                                className="form-control"
                                value={storeDetails.phone}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* City */}
                        <div className="mb-3">
                            <label className="form-label">City</label>
                            <input
                                type="text"
                                name="city"
                                className="form-control"
                                value={storeDetails.city}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* PO */}
                        <div className="mb-3">
                            <label className="form-label">PO</label>
                            <input
                                type="text"
                                name="po"
                                className="form-control"
                                value={storeDetails.po}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Date */}
                        <div className="mb-3">
                            <label className="form-label">Date</label>
                            <input
                                type="date"
                                name="date"
                                className="form-control"
                                value={storeDetails.date}
                                onChange={handleInputChange}
                            />
                        </div>
{/* Time */}
<div>
  <label htmlFor="time">Time</label>
  <select id="time" name="time" value={storeDetails.time} onChange={handleInputChange} required>
    {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
      <option key={hour} value={`${hour}:00 AM`}>{hour}:00 AM</option>
    ))}
    {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
      <option key={hour + 12} value={`${hour}:00 PM`}>{hour}:00 PM</option>
    ))}
    {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
      <option key={hour} value={`${hour}:30 AM`}>{hour}:30 AM</option>
    ))}
    {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
      <option key={hour + 12} value={`${hour}:30 PM`}>{hour}:30 PM</option>
    ))}
  </select>
</div>

                        {/* Cake FL */}
                        <div className="mb-3">
                            <label className="form-label">Cake FL</label>
                            <input
                                type="text"
                                name="cakefl"
                                className="form-control"
                                value={storeDetails.cakefl}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Cake No */}
                        <div className="mb-3">
                            <label className="form-label">Cake No</label>
                            <input
                                type="text"
                                name="cakeno"
                                className="form-control"
                                value={storeDetails.cakeno}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Message */}
                        <div className="mb-3">
                            <label className="form-label">Message</label>
                            <textarea
                                name="message"
                                className="form-control"
                                value={storeDetails.message}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Payment */}
                        <div className="mb-3">
                            <label className="form-label">Payment</label>
                            <input
                                type="text"
                                name="payment"
                                className="form-control"
                                value={storeDetails.payment}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Pick */}
                        <div className="mb-3">
                            <label className="form-label">Pick</label>
                            <input
                                type="text"
                                name="pick"
                                className="form-control"
                                value={storeDetails.pick}
                                onChange={handleInputChange}
                            />
                        </div>

                        <button 
    type="button" // Corrected the type attribute
    className="btn btn-primary w-100" 
    onClick={updateStoreHandler}
>
    Update Store
</button>
                    </form>
                </div>
            </div>
        </AdminLayout>
    );
};

export default EditStore;