import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateNewstoreMutation } from '../../redux/api/storeApi';
import toast from 'react-hot-toast';
import "../Home.css";
import { Link } from 'react-router-dom';

const OrderForm = () => {
  const [formData, setFormData] = useState({
    staffname: '',
    price: '',
    name: '',
    email: '',
    address: '',
    phone: '',
    city: '',
    po: '',
    date: '',
    time: '',
    cakefl: '',
    cakeno: '',
    message: '',
    bakerInstructions: '',
    payment: 'Not Paid',
    depositAmount: '',
  });
  
  const [pickupLocation, setPickupLocation] = useState('');
  const [isOrdering, setIsOrdering] = useState(false);
  const dispatch = useDispatch();
  const [createNewStore] = useCreateNewstoreMutation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsOrdering(true);
    
    try {
      const orderData = { 
        ...formData, 
        payment: formData.payment === 'Deposit' 
          ? `Deposit - $${formData.depositAmount}` 
          : formData.payment,
        message: `${formData.message} **Baker Instruction - ${formData.bakerInstructions}`, 
        pick: pickupLocation 
      };
      await createNewStore(orderData).unwrap();
      toast.success("Order placed successfully!");
      setFormData({
        staffname: '',
        price: '',
        name: '',
        email: '',
        address: '',
        phone: '',
        city: '',
        po: '',
        date: '',
        time: '',
        cakefl: '',
        cakeno: '',
        message: '',
        bakerInstructions: '',
        payment: 'Not Paid',
        depositAmount: '',
      });
      setPickupLocation('');
    } catch (error) {
      toast.error("Failed to place order. Please try again.");
    } finally {
      setIsOrdering(false);
    }
  };

  const handleTimeChange = (event) => {
    const { value } = event.target;
    setFormData(prevState => ({ ...prevState, time: value }));
  };

  return (
    <div className="cake-order-page">
      <div className="container">
        <div className="header">
          <h1>In Store Cashier Order</h1>
          <h2>Staff Orders</h2>
          <Link to={`/store/order`} className="btn btn-outline-primary me-2">
            <i className="fa fa-pencil"></i>
          </Link>
        </div>
        <form onSubmit={submitHandler}>
          {/* ... previous form fields ... */}
          <div className="form-group">
            <div>
              <label htmlFor="staffname">Staff Name</label>
              <input type="text" id="staffname" name="staffname" value={formData.staffname} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="price">Price</label>
              <input type="number" id="price" name="price" value={formData.price} onChange={handleChange} required min="0" step="0.01" />
            </div>
          </div>
          
          <div className="form-group">
            <div>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}  />
            </div>
          </div>
          <div className="form-group">
            <div>
              <label htmlFor="address">Address</label>
              <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="phone">Phone</label>
              <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required/>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label htmlFor="city">City</label>
              <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} />
            </div>
            <div>
              <label htmlFor="po">Postal Code</label>
              <input type="text" id="po" name="po" value={formData.po} onChange={handleChange} />
            </div>
          </div>
          <div className="form-group">
            <div>
              <label htmlFor="date">Date</label>
              <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="time">Time</label>
              <select id="time" name="time" value={formData.time} onChange={handleTimeChange} required>
                {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
                  <option key={hour} value={`${hour}:00 AM`}>{hour}:00 AM</option>
                ))}
                {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
                  <option key={hour + 12} value={`${hour}:00 PM`}>{hour}:00 PM</option>
                ))}
                {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
                  <option key={hour} value={`${hour}:30 AM`}>{hour}:30 AM</option>
                ))}
                {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
                  <option key={hour + 12} value={`${hour}:30 PM`}>{hour}:30 PM</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <div>
              <label htmlFor="cakefl">Cake Flavour</label>
              <input type="text" id="cakefl" name="cakefl" value={formData.cakefl} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="cakeno">Cake Size</label>
              <input 
                type="text" 
                id="cakeno" 
                name="cakeno" 
                value={formData.cakeno} 
                onChange={handleChange} 
                required 
              />
            </div>
          </div>
          <div className="form-group">
            <div>
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
            </div>
            <div>
              <label htmlFor="bakerInstructions">Baker Instructions</label>
              <textarea id="bakerInstructions" name="bakerInstructions" value={formData.bakerInstructions} onChange={handleChange}></textarea>
            </div>
          </div>
         
          <div className="form-group">
            <div>
              <label htmlFor="payment">Payment Status</label>
              <select id="payment" name="payment" value={formData.payment} onChange={handleChange}>
                <option value="Not Paid">Not Paid</option>
                <option value="Deposit">Deposit</option>
                <option value="Paid">Paid</option>
              </select>
            </div>
            {formData.payment === 'Deposit' && (
              <div>
                <label htmlFor="depositAmount">Deposit Amount ($)</label>
                <input 
                  type="number" 
                  id="depositAmount" 
                  name="depositAmount" 
                  value={formData.depositAmount} 
                  onChange={handleChange}
                  min="0" 
                  step="0.01" 
                  required 
                />
              </div>
            )}
            <div>
              <label htmlFor="pick">Pickup Location</label>
              <select 
                id="pick" 
                name="pick" 
                value={pickupLocation} 
                onChange={(e) => setPickupLocation(e.target.value)} 
                required
              >
                <option value="" disabled>Select Location</option>
                <option value="Surrey">Surrey</option>
                <option value="Abbotsford">Abbotsford</option>
              </select>
            </div>
          </div>
          <button type="submit" disabled={isOrdering}>{isOrdering ? 'Placing Order...' : 'Place Order'}</button>
        </form>
      </div>
    </div>
  );
};

export default OrderForm;