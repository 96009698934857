import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPriceQueryParams } from "../../helpers/helpers";
import StarRatings from "react-star-ratings";

const PRODUCT_CATEGORIES = [
    "Special", "Snacks", "HighTea", "Wedding", "Workshop",
    "Fruit", "Chocolate", "Cheese Cake", "Pinata Cake",
    "Ready To Go", "Heartshape Cake", "Anniversary Cake",
    "Babyshower Cake", "Birthday Cake", "Designer Cake",
    "Cakes For Boys", "Cakes For Girls", "Wedding Cakes",
    "Bridal Showercake", "Baby Shower", "Fondant Cakes",
    "Decoration", "Bakery Products"
];

const Filters = () => {
    const [min, setMin] = useState("");
    const [max, setMax] = useState("");
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.has('min')) setMin(searchParams.get('min'));
        if (searchParams.has('max')) setMax(searchParams.get('max'));
    }, [searchParams]);

    // Handle Category & Rating with case-insensitive comparison
    const handleClick = (checkbox) => {
        const checkboxes = document.getElementsByName(checkbox.name);

        checkboxes.forEach((item) => {
            if (item !== checkbox) item.checked = false;
        });

        if (!checkbox.checked) {
            // Remove filter from query if unchecked
            if (searchParams.has(checkbox.name)) {
                searchParams.delete(checkbox.name);
            }
        } else {
            if (checkbox.name === 'category') {
                // For categories, preserve the original case
                const originalCategory = PRODUCT_CATEGORIES.find(
                    cat => cat.toLowerCase() === checkbox.value.toLowerCase()
                );
                searchParams.set(checkbox.name, originalCategory || checkbox.value);
            } else {
                // For ratings and other filters
                searchParams.set(checkbox.name, checkbox.value);
            }
        }

        // Update the URL with the new search parameters
        const newPath = window.location.pathname + "?" + searchParams.toString();
        navigate(newPath);
    };

    // Handle price filter
    const handleButtonClick = (e) => {
        e.preventDefault();
        searchParams = getPriceQueryParams(searchParams, "min", min);
        searchParams = getPriceQueryParams(searchParams, "max", max);
        const path = window.location.pathname + "?" + searchParams.toString();
        navigate(path);
    };

    // Case-insensitive check for category
    const isChecked = (checkboxType, checkboxValue) => {
        const value = searchParams.get(checkboxType);
        if (checkboxType === 'category') {
            return value && value.toLowerCase() === checkboxValue.toLowerCase();
        }
        return value === checkboxValue.toString();
    };

    return (
        <div className="border p-3 filter">
            <h3>Filters</h3>
            <hr />
            <h5 className="filter-heading mb-3">Price</h5>
            <form id="filter_form" className="px-2" onSubmit={handleButtonClick}>
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Min ($)"
                            name="min"
                            value={min}
                            onChange={(e) => setMin(e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Max ($)"
                            name="max"
                            value={max}
                            onChange={(e) => setMax(e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <button type="submit" className="btn btn-primary">GO</button>
                    </div>
                </div>
            </form>
            <hr />
            <h5 className="mb-3">Category</h5>
            {PRODUCT_CATEGORIES.map((category, index) => (
                <div className="form-check" key={index}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="category"
                        id={`category-check-${index}`}
                        value={category}
                        checked={isChecked("category", category)}
                        onChange={(e) => handleClick(e.target)}
                    />
                    <label className="form-check-label" htmlFor={`category-check-${index}`}>
                        {category}
                    </label>
                </div>
            ))}
            <hr />
            <h5 className="mb-3">Ratings</h5>
            {[5, 4, 3, 2, 1].map((rating, index) => (
                <div className="form-check" key={`rating-${rating}`}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="ratings"
                        id={`rating-check-${rating}`}
                        value={rating}
                        checked={isChecked("ratings", rating)}
                        onChange={(e) => handleClick(e.target)}
                    />
                    <label className="form-check-label" htmlFor={`rating-check-${rating}`}>
                        <StarRatings
                            rating={rating}
                            starRatedColor="#ffb829"
                            numberOfStars={5}
                            name={`rating-${rating}`}
                            starDimension="21px"
                            starSpacing="1px"
                        />
                    </label>
                </div>
            ))}
        </div>
    );
};
export default Filters;