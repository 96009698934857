export const PRODUCT_CATEGORIES = [
            "Special", 
            "Snacks", 
            "HighTea", 
            "Wedding",  
            "Workshop",
            "Fruit",
            "Chocolate",
            "Cheese Cake",
             "Pinata Cake" ,
             "Ready To Go", 
             "Heartshape Cake",
              "Anniversary Cake", 
              "Babyshower Cake", 
              "Birthday Cake", "Designer Cake", 
              "Cakes For Boys", 
              "Cakes For Girls", 
              "Wedding Cakes",
              "Bridal Showercake", 
              "Baby Shower",
               "Fondant Cakes",
               "Decoration",
               "Bakery Products",
        ];