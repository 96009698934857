import React, { useState, useEffect } from "react";
import "../App.css";

const PromoModal = ({ onClose }) => {
  const [sprinkles, setSprinkles] = useState([]);

  // Generate sprinkles animation on load
  useEffect(() => {
    const sprinkleColors = ["#FFD700", "#FF69B4", "#ADFF2F", "#00BFFF", "#FF4500"];
    const generatedSprinkles = Array.from({ length: 50 }).map(() => ({
      color: sprinkleColors[Math.floor(Math.random() * sprinkleColors.length)],
      left: `${Math.random() * 100}%`,
      animationDelay: `${Math.random() * 2}s`,
    }));
    setSprinkles(generatedSprinkles);
  }, []);

  return (
    <div className="promo-modal-overlay">
      <div className="promo-modal">
        <button className="promo-close-btn" onClick={onClose}>
          ✖
        </button>
        <h2 className="promo-title">Welcome to Vegetarian Delite Bakery!</h2>
        <p className="promo-message">
          We're excited to introduce our new website! As a special promo, all
          deliveries to <strong>Abbotsford</strong> and <strong>Surrey</strong>{" "}
          are <strong>free</strong> this month.
        </p>
        <p className="promo-terms">
          <strong>Terms and Conditions:</strong> <br />
          - Offer valid only for orders placed in December 2024. <br />
          - Free shipping applies only to Abbotsford and Surrey. <br />
          - Minimum order of $20 required for free delivery. <br />
          - Limited to standard cakes only (custom orders excluded).
        </p>
        <button
          className="promo-order-btn"
          onClick={() => (window.location.href = '/ourcakes')}
        >
          Order Now
        </button>
        <div className="promo-sprinkles">
          {sprinkles.map((sprinkle, index) => (
            <span
              key={index}
              className="sprinkle"
              style={{
                backgroundColor: sprinkle.color,
                left: sprinkle.left,
                animationDelay: sprinkle.animationDelay,
              }}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PromoModal;
