import React, { useState, useEffect } from 'react';
import MetaData from "./layout/MetaData";
import { useGetProductQuery } from "../redux/api/productsApi";
import ProductItem from "../components/product/ProductItem";
import Loader from "./layout/Loader";
import toast from 'react-hot-toast';
import CustomPagination from './layout/CustomPagination';
import { useSearchParams } from 'react-router-dom';
import Filters from './layout/Filters';
import "../components/test/style.css"

const OurCakes = () => {
  const [searchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);
  const page = searchParams.get("page") || 1;

  const keyword = searchParams.get("keyword") || "";
  const min = searchParams.get("min");
  const max = searchParams.get("max");
  const category = searchParams.get("category");
  const ratings = searchParams.get("ratings");

  const params = { page, keyword };

  min !== null && (params.min = min);
  max !== null && (params.max = max);
  category !== null && (params.category = category);
  ratings !== null && (params.ratings = ratings);

  const { data, isLoading, error, isError } = useGetProductQuery(params);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message || "An error occurred while fetching products.");
    }
  }, [isError, error]);

  const columnSize = 4;

  if (isLoading) return <Loader />;

  const products = Array.isArray(data) ? data : data?.products || [];

  return (
    <div className="cake-shop">
      <MetaData title={'Welcome to Our Cake Shop'} />
      
      {/* Hamburger menu icon for mobile */}
      <div className="cake-shop__hamburger d-md-none" onClick={() => setShowFilters(!showFilters)}>
        <div className={`cake-shop__hamburger-icon ${showFilters ? 'open' : ''}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cake-shop__layout">
        {/* Sidebar for filters */}
        <div className={`cake-shop__filter-sidebar ${showFilters ? 'show' : ''}`}>
          <Filters />
        </div>

        <div className="cake-shop__main-content">
          <h1 className="cake-shop__heading">
          {keyword ? 
  `${data?.products?.length} Products Found With keyword: ${keyword}` 
  : 
  <span style={{
    fontWeight: 'bold',
    textDecoration: 'underline'
  }}>
    Our Cakes
  </span>
}
          </h1>
          <section className="cake-shop__products">
            <div className="cake-shop__products-grid">
              {products.length > 0 ? (
                products.map((product) => (
                  <ProductItem 
                    key={product.id} 
                    product={product} 
                    columnSize={columnSize}
                    className="cake-shop__product-item" 
                  />
                ))
              ) : (
                <p className="cake-shop__no-products">
                <img className="cake-shop__no-products-image" src="../images/soon.jpg" alt="No products available" />
               
              </p>
              

              )}
            </div>
          </section>
          <div className="cake-shop__pagination">
            <CustomPagination 
              resPerPage={data?.resPerPage} 
              filtererdProductsCount={data?.filtererdProductsCount} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCakes;